import bridge from "../../assets/img/partners/bridge.png"
import trt from "../../assets/img/partners/trt.png"
import redbull from "../../assets/img/partners/redbull.png"
import telus from "../../assets/img/partners/telus.png"
import somos1 from "../../assets/img/servicios/booking.jpg"

const Inicio = () => {
    return (
        <section
            className="page-section text-white mb-0 text-center"
            id="inicio"
        >
            <div className="container d-flex align-items-center justify-content-center mb-4">
                <h2>Agencia</h2>
            </div>

            <div className="container">
                <div className="row row-inicio">
                    <div className="inicio">
                         <img
                                src={somos1}
                                alt={somos1}
                                id="somos1"
                                width={220}
                                className="p-2"
                        />
                        <div>
                            <p className="px-2 text-center">
                                Si estás buscando una agencia especializada en el <strong>booking, management y producción de giras, conciertos y eventos en España</strong>, ¡has llegado al lugar adecuado!
                            </p>
                            <p className="px-2 text-center">
                                En nuestra agencia contamos con un equipo de profesionales altamente capacitados en la <strong>organización y producción de eventos en vivo</strong>.
                            </p>
                            <p className="px-2 text-center">
                                Nos dedicamos a trabajar con artistas de todos los géneros y tamaños, desde <strong>músicos locales hasta artistas internacionales de renombre</strong>. Nuestra agencia ofrece servicios personalizados para satisfacer las necesidades únicas de cada cliente, y nos aseguramos de brindar un servicio de alta calidad en cada paso del camino.                
                            </p>
                            <p className="px-2 text-center">
                                En 22:22, nos encargamos de todo lo necesario para hacer que tu evento sea un éxito. Desde la <strong>gestión de la logística y la contratación de equipos y personal técnico</strong>, hasta la <strong>promoción y venta de entradas</strong>. Además, también ofrecemos servicios de <strong>booking y management</strong> para ayudarte a obtener más oportunidades de conciertos y a gestionar tu carrera musical.                
                            </p>
                            <p className="px-2 text-center">
                                Para <strong>booking, management y producción general de giras, conciertos y eventos en España</strong>, no busques más. Contáctanos hoy para obtener más información sobre cómo podemos ayudarte a hacer realidad tus sueños musicales y a crear un evento inolvidable.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/** 
            <div className="container d-flex align-items-center justify-content-center mb-4">
                <h2>Instagram Feed</h2>
            </div>
            */}
            
            <div className="container d-flex align-items-center justify-content-center mt-4">
                <h2>Partners</h2>
            </div>
            <div className="partners container d-flex justify-content-center">
                <div className="d-flex align-items-center">
                    <div className="col-sd-6 col-lg-3 text-center p-2">
                        <div className="portfolio-item mx-auto">
                            <img
                                className="img-fluid"
                                src={trt}
                                width={100}
                                height={100}
                                alt="..."
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center p-2">
                        <div className="portfolio-item mx-auto">
                            <img
                                className="img-fluid"
                                src={bridge}
                                width={100}
                                height={100}
                                alt="..."
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center p-2">
                        <div className="portfolio-item mx-auto">
                            <img
                                className="img-fluid"
                                src={redbull}
                                width={100}
                                height={100}
                                alt="..."
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 text-center p-2">
                        <div className="portfolio-item mx-auto">
                            <img
                                className="img-fluid"
                                src={telus}
                                width={100}
                                height={100}
                                alt="..."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Inicio
