import React from "react"
import newLogo from "../../assets/img/logonew2222.png"
import logoPampa from "../../assets/img/LogoPampaWhite.png"
import styles from "./styles.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const Footer = () => {
    return (
        <footer className="footer text-center">
            <div className="container">
                <div className="align-items-center justify-content-center">
                
                    
                    {/*<!-- Footer Nosotros Text-->*/}
                    <div className="">
                        <img id="newLogo" src={newLogo} alt="2222 Agencia" width={150} className="mb-1"/>
                        <p className="lead">Barcelona, España.</p>
                    </div>
                    <div className="">
                        <a
                            className="btn btn-outline-light btn-social mx-1 mt-3"
                            href="https://www.instagram.com/2222agencia/"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <i className="fab fa-fw fa-instagram"></i>
                        </a>
                        <a
                            className="btn btn-outline-light btn-social mx-1 mt-3"
                            href="https://wa.me/+34667569323"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <i className="fab fa-fw fa-whatsapp"></i>
                        </a>
                        <a
                            className="btn btn-outline-light btn-social mx-1 mt-3"
                            href={"mailto:info@2222agencia.com"}
                        >
                            <i className="fas fa-envelope"></i>
                        </a>
                    </div>
                </div>
                    {/*<!-- Footer Social Icons-->*/}
                    <div className="">
                        <p className="lead mt-4">
                            Desarrollado por  
                            <a 
                                href="https://www.pampacode.com"
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <img src={logoPampa} alt="Pampacode" width={50} />
                            </a>
                        </p>
                    </div>
            </div>
        </footer>
    )
}

export default Footer
