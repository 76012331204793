import iconoLugar from "../../assets/img/icons/icono-lugar.svg"
import iconoTicket from "../../assets/img/icons/icono-ticket.svg"
import iconoFecha from "../../assets/img/icons/icono-fecha.svg"
import cirogira from "../../assets/img/shows/ciro-gira-europa.jpg"
import elzarbarcelona from "../../assets/img/shows/elzar-barcelona.jpeg"
import elzarmadrid from "../../assets/img/shows/elzar-madrid.jpeg"

const Eventos = () => {
    return (
        <section className="page-section portfolio bg-secundary" id="events">
            <div className="container container-body">

            <div className="meses">
                <div className="eventos-mes">SEPT</div>
                <div className="eventos-mes-mobile">SEPTIEMBRE</div>
                    <div className="eventos">
                        <div className="evento-1">
                            <div className="artista-nombre">
                                El Zar - Madrid
                            </div>
                            <img className="img-shows" src={elzarmadrid} width={220} height={220} alt={elzarmadrid}/>
                            <div className="lugar">
                                <div className="lugar-div">
                                    <div className="lugar-nombre">
                                        Café Berlín
                                    </div>
                                    <img src={iconoLugar} width={30} height={30} alt={iconoLugar}/>
                                </div>
                                <div className="lugar-div">
                                    <div className="lugar-nombre">
                                        15/09 | 21hs
                                    </div>
                                    <img src={iconoFecha} width={30} height={30} alt={iconoFecha}/>
                                </div>
                                <div className="lugar-div lugar-div-entrada">
                                    <a
                                    href="https://www.enterticket.es/eventos/el-zar-en-madrid-cafe-berlin-991574"
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    >
                                        <div className="lugar-fecha">
                                        Comprar entrada
                                    </div>
                                    <img src={iconoTicket} width={30} height={30} alt={iconoTicket}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="evento-1">
                            <div className="artista-nombre">
                                El Zar - Bcn
                            </div>
                            <img className="img-shows" src={elzarbarcelona} width={220} height={220} alt={elzarbarcelona}/>
                            <div className="lugar">
                                <div className="lugar-div">
                                    <div className="lugar-nombre">
                                        Razzmatazz
                                    </div>
                                    <img src={iconoLugar} width={30} height={30} alt={iconoLugar}/>
                                </div>
                                <div className="lugar-div">
                                    <div className="lugar-nombre">
                                        23/09 | 21:30hs
                                    </div>
                                    <img src={iconoFecha} width={30} height={30} alt={iconoFecha}/>
                                </div>
                                <div className="lugar-div lugar-div-entrada">
                                    <a
                                    href="https://www.enterticket.es/eventos/el-zar-en-barcelona-razzmatazz-359047"
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    >
                                        <div className="lugar-fecha">
                                        Comprar entrada
                                    </div>
                                    <img src={iconoTicket} width={30} height={30} alt={iconoTicket}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="evento-1">
                            <div className="artista-nombre">
                                Ciro y Los Persas
                            </div>
                            <img className="img-shows" src={cirogira} width={220} height={220} alt={cirogira}/>
                            <div className="lugar">
                                <div className="lugar-div">
                                    <div className="lugar-nombre">
                                        Gira Europa
                                    </div>
                                    <img src={iconoLugar} width={30} height={30} alt={iconoLugar}/>
                                </div>
                                <div className="lugar-div lugar-div-entrada">
                                    <a
                                    href="https://trtproducciones.com/"
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    >
                                        <div className="lugar-fecha">
                                        Compr&aacute; tu entrada
                                    </div>
                                    <img src={iconoTicket} width={30} height={30} alt={iconoTicket}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Eventos
