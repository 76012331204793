import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

import booking from "../../assets/img/servicios/booking.jpg"
import estudio from "../../assets/img/servicios/estudio.jpg"
import eventos from "../../assets/img/servicios/eventos.jpg"
import logistica from "../../assets/img/servicios/logistica.png"
import produccion from "../../assets/img/servicios/produccion.jpg"
import ticketing from "../../assets/img/servicios/ticketing.jpg"

const Servicios = () => {
    return (
        <section className="page-section text-white mb-0 text-center" id="services">
            <div className="container container-body">
                <div className="row justify-content-center">
                    <div className="row mb-4">
                           <div>
                                <p className="px-2 text-left">
                                    En nuestro equipo de trabajo contamos con profesionales altamente capacitados y comprometidos en brindar experiencias únicas en cada evento.
                                    Desde la planificación hasta la ejecución, nos enfocamos en la creatividad y atención al detalle para superar las expectativas de nuestros clientes y hacer que cada evento sea inolvidable
                                </p>
                            </div>
                    </div>
                    {/*<!-- 1 -->*/}
                    <Row>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="ml-3">
                                            <Card.Title className="mb-2 text-left">Producción general de giras y conciertos</Card.Title>
                                            <img
                                            src={produccion}
                                            alt="Imagen"
                                            className="img-fluid rounded"
                                            width={400}
                                            />
                                            <Card.Text className="mt-2 mb-2 text-left">
                                                Nos encargamos de coordinar todos los aspectos logísticos y técnicos para su correcto desarrollo. Desde el montaje hasta la desinstalación, cada detalle es planificado y supervisado para garantizar  la satisfacción de los asistentes y el éxito del evento.                                    
                                            </Card.Text>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="ml-3">
                                            <Card.Title className="mb-2 text-left">Logística</Card.Title>
                                            <img
                                            src={logistica}
                                            alt="Imagen"
                                            className="img-fluid rounded"
                                            width={400}
                                            />
                                            <Card.Text className="mt-2 mb-2 text-left">
                                                La logística giras de conciertos implica la planificación, organización y coordinación de todos los aspectos técnicos, de transporte y alojamiento de los artistas y su equipo.
                                                Una gestión adecuada asegura una gira exitosa y sin contratiempos.    
                                            </Card.Text>                         
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {/*<!-- 3 -->*/}
                    <Row>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="ml-3">
                                            <Card.Title className="mb-2 text-left">Ticketing</Card.Title>
                                            <img
                                            src={ticketing}
                                            alt="Imagen"
                                            className="img-fluid rounded"
                                            width={400}
                                            />
                                            <Card.Text className="mt-2 mb-2 text-left">
                                                Fundamental para el éxito de eventos. Nos Permite un acceso organizado y controlado, y facilita el seguimiento de los asistentes y la contabilidad. Con herramientas digitales, se pueden simplificar estos procesos y hacerlos más eficientes.
                                            </Card.Text>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="ml-3">
                                            <Card.Title className="mb-2 text-left">Eventos corporativos</Card.Title>
                                            <img
                                            src={eventos}
                                            alt="Imagen"
                                            className="img-fluid rounded"
                                            width={400}
                                            />
                                            <Card.Text className="mt-2 mb-2 text-left">
                                                La producción de eventos corporativos implica la coordinación de múltiples elementos, desde la logística y la selección de proveedores, hasta el catering y la música.
                                                Prestamos mucha atención a cada detalle, ya que, son cruciales para crear una experiencia memorable para los asistentes y lograr los objetivos de la empresa.                                        
                                            </Card.Text>                         
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/*<!-- 5 -->*/}
                    <Row>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="ml-3">
                                            <Card.Title className="mb-2 text-left">Booking y management de artistas</Card.Title>
                                            <div>
                                                <img
                                                src={booking}
                                                alt="Imagen"
                                                className="img-fluid rounded"
                                                width={400}
                                                />
                                            </div>
                                            <Card.Text className="mt-2 mb-2 text-left">
                                                En nuestra agencia, ofrecemos servicios integrales de booking y management para artistas en la industria musical. Nos especializamos en la representación y el desarrollo de carreras artísticas de alto nivel, trabajando con artistas de diversas categorías. Nuestro equipo altamente capacitado ofrece un enfoque personalizado y estratégico para garantizar el éxito de cada artista en el mercado musical. 
                                            </Card.Text>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex">
                                        <div className="ml-3">
                                            <Card.Title className="mb-2 text-left">Estudios de grabación</Card.Title>
                                            <img
                                            src={estudio}
                                            alt="Imagen"
                                            className="img-fluid rounded"
                                            width={400}
                                            />
                                            <Card.Text className="mt-2 mb-2 text-left">
                                                Tenemos convenios con las mejores salas de España. Acceso a equipos de primera calidad, asesoramiento en sonido, grabaciones y digitalizaciones de contenido. Acceso a espacios acuatizados y preparados para sesiones en vivo.
                                            </Card.Text>                         
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default Servicios
