const Blog = () => {
    return (
        <section
            className="page-section text-white mb-0 text-center"
            id="blog"
        >
            <div className="container d-flex align-items-center justify-content-center mb-4">
                <h2>En construcci&oacute;n</h2>
            </div>
        </section>
    )
}

export default Blog
